.center {
    margin: 0 auto;
    min-width: none;
    max-width: 1000px;
    text-align: center;
}

li {
    list-style: none;
}

.welcome {
    font-family: 'Helvetica For Target', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
}

html {
    touch-action: none; /* To prevent zooming in */
    height: 100%;
}

.header {
    padding-top: 2%;
}

.targetLogo {
    color: #CC0000;
    height: 18vw;
}

.homePageButtonDiv {
    width: 80vw;
}

.nameDiv {
    padding-top: 2vh;
    font-size: 5vw;
}

.InfoRowDiv {
    text-align: left;
    font-size: 4vw;
    width: 90%;
}

.icondiv {
    width: 10vw;
    height: 10vw;
    position: relative;
}

@media (min-width: 1000px) {
    .welcome {
        font-size: 2.5rem;
    }
    .noteDiv {
        max-width: 700px;
    }
    .homePageButtonDiv {
        max-width: 700px;
    }

    .targetLogo {
        color: #CC0000;
        height: 180px;
    }

    .nameDiv {
        padding-top: 2vh;
        font-size: 50px;
    }

    .InfoRowDiv {
        text-align: left;
        font-size: 50px;
        width: 90%;
    }

    .icondiv {
        width: 100px;
        height: 100px;
        position: relative;
    }
  }

.homePageInteractablesDiv {
    max-height: 20vh;
}


.homePageButtonTextDiv {
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 1vh;
    font-size: 2.5vh
}

@media (max-width: 300px) {
    .homePageButtonTextDiv {
        font-size: 1.5vh;
    }
}


.homePageButtonDiv {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.homePageButtonDiv button {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    height: 18vh;
    display: block;
    border-radius: 15px;
    font-size: 1.5rem;
    color: #ffff;
    background-color: #CC0000;
    border-style: none;
}

.homePageButtonDiv button:hover {
    background-color: #cc0000b5;
}

.noteDiv {
    width: 100%;
    font-size: 1.3rem;
    padding: 0 10px;
    position: relative;
    bottom: 0;
    margin-bottom: 20px;
}

.mobileBadgeSpan {
    padding: 30%;
}

.qrCode {
    padding-bottom: 2vh;
}

.underlineDiv {
    text-decoration: underline;
    display: inline-block;
}

.modalText {
    text-align: left;
    padding-top: 5%;
}

.extraBold {
    text-shadow: 1px 0 0;
    letter-spacing: 1px;
    font-weight: bold;
}

.icondiv img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.rowContainer {
    width: '100%';
    margin: 0;
    padding-left: 12px;
}

.termsDiv {
    max-height: 50vmax;
    width: 80%;
    font-size: 1rem;
    text-align: left;
    overflow-x: unset;
    overflow-y: auto; 
    color: black;
    background-color: gainsboro;
}

.termsApproval {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    height: 5vh;
    display: block;
    border-radius: 15px;
    font-size: 1rem;
    color: #ffff;
    background-color: #CC0000;
    border-style: none;
}

.termsApproval button:hover {
    background-color: #cc0000b5;
}

.paragraph-header {
    font-size: 18px;
    text-align: center;
}
.paragraph-p1{
    font-size: 14px;
    text-align: justify;
    text-justify: justify;
}
.li-1{
    font-size: 14px;
    text-align: justify;
    text-justify: justify;
    padding-top: 10px;
}
.column {
    float: left;
    width: 50%;
    padding: 10px;
    height: 300px; 
  }
  
  .row:after {
    content: "";
    display: table;
    clear: both;
  }